// -----------------------
// Buttons mixin
// -----------------------
@mixin button($class: false) {

  // --------------------------------
  // Default
  // --------------------------------
  @if $class == button {
    background: transparent;
    //background-size: 200% 100%;
    background-position: right bottom;
    border-color: $primary-color;
    border-radius: 0;
    border-width: 3px;
    border-style: solid;
    color: $primary-color;
    display: inline-block;
    font-family: $body-font-family;
    font-size: rem-calc(18);
    font-weight: $global-weight-bold;
    line-height: 1;
    margin-bottom: $global-margin;
    padding: ($global-padding / 1.11) (34px);
    //position: relative;
    text-align: center;
    transition: all .5s linear;
    vertical-align: center;
    width: 100%;

    @include breakpoint(medium) {
      padding: ($global-padding / 1.11) 50px;
      min-width: 264px;
      width: auto;
    }

    &:hover {
      cursor: pointer;
    }

    &:hover,
    &:active,
    &:focus {
      background-position: left bottom;
      border-color: $primary-color;
      color: $primary-color;
    }
  }

  // --------------------------------
  // Primary
  // --------------------------------
  @if $class == button--primary {
    background: linear-gradient( to left, $primary-color 50%, transparent 50% );
    background-size: 200% 99%;
    background-position: right bottom;
    border-color: $primary-color;
    color: $black;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      background-position: left bottom;
      border-color: $primary-color;
      color: $primary-color;
    }
  }

  // --------------------------------
  // Primary outline
  // --------------------------------
  @if $class == button--outline-primary {
    background: linear-gradient( to left, transparent 50%, $primary-color 50% );
    background-size: 200% 99%;
    background-position: right bottom;
    border-color: $primary-color;
    color: $primary-color;

    &:hover,
    &:active,
    &:focus {
      background-position: left bottom;
      border-color: $primary-color;
      color: $secondary-color;
    }
  }

  // --------------------------------
  // Secondary
  // --------------------------------
  @if $class == button--secondary {
    background: linear-gradient( to left, $secondary-color 50%, transparent 50% );
    background-size: 200% 99%;
    background-position: right bottom;
    border-color: $secondary-color;
    color: $primary-color;

    &:hover,
    &:active,
    &:focus {
      background-position: left bottom;
      border-color: $secondary-color;
      color: $secondary-color;
    }
  }

  // --------------------------------
  // Secondary outline
  // --------------------------------
  @if $class == button--outline-secondary {
    background: linear-gradient( to left, transparent 50%, $secondary-color 50% );
    background-size: 200% 99%;
    background-position: right bottom;
    border-color: $secondary-color;
    color: $secondary-color;

    &:hover,
    &:active,
    &:focus {
      background-position: left bottom;
      border-color: $secondary-color;
      color: $primary-color;
    }
  }

  // --------------------------------
  // White
  // --------------------------------
  //@if $class == button--white {
  //  background-color: $white;
  //  border-color: $white;
  //  color: $secondary-color;
  //
  //  &:hover,
  //  &:active,
  //  &:focus {
  //    background-color: transparent;
  //    border-color: $white;
  //    color: $white;
  //  }
  //}

  // --------------------------------
  // White outline
  // --------------------------------
  @if $class == button--outline-white {
    background: linear-gradient( to left, transparent 50%, $white 50% );
    background-size: 200% 99%;
    background-position: right bottom;
    border-color: $white;
    color: $white;

    &:hover,
    &:active,
    &:focus {
      background-position: left bottom;
      border-color: $white;
      color: $secondary-color;
    }
  }

  // --------------------------------
  // Arrow button
  // --------------------------------
  //@if $class == button--arrow {
  //  background-color: transparent;
  //  border: 0;
  //  color: $white;
  //  font-size: rem-calc(18);
  //  font-weight: $global-weight-bold;
  //  margin-left: $global-margin * 2;
  //  padding: 0;
  //  position: relative;
  //  width: auto;
  //
  //  &::before {
  //    content: url("../images/icons/arrow-lime.svg");
  //    display: inline-block;
  //    position: absolute;
  //    left: -($global-margin * 2);
  //    top: 50%;
  //    transform: translateY(-50%);
  //  }
  //
  //  &:hover,
  //  &:active,
  //  &:focus {
  //    background-color: transparent;
  //    color: $primary-color;
  //  }
  //}

  // --------------------------------
  // Small
  // --------------------------------
  //@if $class == button--small {
  //  padding-top: $global-padding * .85;
  //  padding-bottom: $global-padding * .8;
  //  min-height: rem_calc(40);
  //}
}
