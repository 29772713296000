@import "../settings";

// Stop full bleed images becoming too huge.
$global-max-bleed-size: 1000px;

.content-image {
  padding-top: 0;
  padding-bottom: $global-padding;

  @include breakpoint(medium) {
    padding-top: $global-padding * 2;
  }

  @include breakpoint(large) {
    padding-bottom: $global-padding * 3.8;
    padding-top: $global-padding * 3.8;
  }

  // Overrides for padding and adjacent block stuff.
  // @todo i bet we spend a lot of time here after content is added. We might have to add rules to _blocks.scss for overrides
  & + .content-image {
    @include breakpoint(medium) {
      padding-top: 0;
    }
  }

  // Add padding to the top of the block if it's not a padding block so it doesnt look broken..
  & + .block:not(.block--padding) {
    padding-top: $global-padding * 3;

    @include breakpoint(medium) {
      padding-top: $global-padding * 3;
    }

    @include breakpoint(large) {
      padding-top: $global-padding * 6.5;
    }
  }

  &:has(.content-image__overlay) {
    @include breakpoint(medium) {
      padding-bottom: $global-padding * 7.5;
    }

    @include breakpoint(large) {
      padding-bottom: $global-padding * 13.5;
    }
  }

  &__grid {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @supports (display: grid) {
      display: grid;
      grid-template-columns: 1fr;
    }

    @include breakpoint(medium) {
      @supports (display: grid) {
        grid-template-columns: repeat(12, 1fr);
      }
    }
  }

  &__content {
    align-content: center;
    flex: 0 0 100%;
    max-width: 100%;

    @include breakpoint(small only) {
      padding-top: $global-padding * 2;
      padding-bottom: $global-padding * 2;
    }

    @include breakpoint(medium) {
      flex-basis: calc(50% - #{$global-margin * 3});
      max-width: calc(50% - #{$global-margin * 3});
    }

    @supports (display: grid) {
      display: grid;
      max-width: 460px;
    }

    & > *:last-child {
      margin-bottom: 0;

      & *:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__container {
    max-width: 100%;
    position: relative;

    @include breakpoint(medium) {
      flex-basis: calc(50% - #{$global-margin * 3});
      max-width: calc(50% - #{$global-margin * 3});
    }

    @supports (display: grid) {
      max-width: 100%;
    }
  }

  // Wrapper when figure is used.
  &__figure {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;

    @include breakpoint(medium) {
      flex-basis: calc(50% - #{$global-margin * 3});
      max-width: calc(50% - #{$global-margin * 3});
    }

    @supports (display: grid) {
      max-width: 100%;
    }

    .content-image__image {
      position: initial;
    }
  }

  &__image {
    aspect-ratio: 1 / 1.5;
    height: 100%;
    // This number need tweaking depending on the design. But this stops the image constantly stretching. Change it in settings.scss
    min-height: 350px;
    max-height: 400px;
    object-fit: contain;
    width: 100%;

    @include breakpoint(medium) {
      max-height: 640px;
      object-fit: contain;
    }
  }

  // Small image placed over the main image.
  &__overlay {
    bottom: -($global-margin * 5);
    height: auto;
    max-height: 240px;
    max-width: 200px;
    object-fit: cover;
    position: absolute;
    right: 0;
    //transition: all .4s ease-in-out;
    width: 100%;

    @include breakpoint(large) {
      bottom: -($global-margin * 9.5);
      max-height: 360px;
      max-width: 275px;
    }

    @include breakpoint(xlarge) {
      bottom: -($global-margin * 9.5);
      max-height: 460px;
      max-width: 375px;
    }
  }

  &--left {
    @include breakpoint(medium) {
      .content-image__container,
      .content-image__figure {
        grid-column: 1 / 7;
      }

      .content-image__content {
        grid-column: 8 / -1;
        grid-row: 1 / 1;
      }
    }
  }

  &--right {
    @include breakpoint(medium) {
      .content-image__content {
        grid-column: 1 / 6;
        order: 1;
      }

      .content-image__container,
      .content-image__figure {
        grid-column: 7 / -1;
        grid-row: 1 / 1;
        order: 2;
      }
    }

    // Small image placed over the main image.
    .content-image__overlay {
      left: 0;
    }
  }

  // Full bleed images options.
  &--full-bleed {

    .content-image__content {
      padding: ($global-padding * 4) ($global-padding * 2);

      @include breakpoint(medium) {
        padding: ($global-padding * 4) $global-padding ($global-padding * 4) 0;
        max-width: 460px;
      }

      @include breakpoint(xlarge) {
        padding: ($global-padding * 6) $global-padding ($global-padding * 6) 0;
      }
    }

    &:has(.content-image__overlay) {
      .content-image__content {
        @include breakpoint(small only) {
          padding: ($global-padding * 7) ($global-padding) $global-padding $global-padding;
        }
      }
    }

    .content-image__container {
      height: 100%;
      max-width: $global-max-bleed-size;
      width: 100%;
    }

    .content-image__image {
      object-fit: cover;

      @include breakpoint(medium) {
        max-height: 670px;
        min-height: 670px;
        object-fit: cover;
      }

      @include breakpoint(large) {
        max-height: 830px;
        min-height: 830px;
      }
    }

    .content-image--left {
      .content-image__container {
        @include breakpoint(xxlarge) {
          margin-left: auto;
        }

        &:has(.content-image__overlay) {
          .content-image__image {
            @include breakpoint(medium) {
              padding-right: $global-padding * 4.5;
            }
          }
        }
      }

      .content-image__content {

        @include breakpoint(medium) {
          grid-column: 8 / 13;
        }

        @include breakpoint(xlarge) {
          margin-right: calc((100% - (#{$global-width / 2} - (#{$global-padding}))));
        }

        @include breakpoint(xxlarge) {
          grid-column: 8 / 12;
          margin-right: calc((100% - (#{$global-width / 2} - (#{$global-padding}))));
        }
      }
    }

    .content-image--right {
      .content-image__container {
        @include breakpoint(xxlarge) {
          margin-right: auto;
        }

        &:has(.content-image__overlay) {
          .content-image__image {
            @include breakpoint(medium) {
              padding-left: $global-padding * 4.5;
            }
          }
        }
      }

      .content-image__content {

        @include breakpoint(medium) {
          padding-left: $global-padding;
          grid-column: 1 / 6;
        }

        @include breakpoint(xlarge) {
          grid-column: 2 / 7;
          padding-left: 0;
        }

        @include breakpoint(xxlarge) {
          margin-left: calc((100% - (#{$global-width / 2} - (#{$global-padding}))));
        }
      }
    }
  }
}
