// Mixin to define width of an element in 12 column grid.
@mixin mon-cols($grid-columns: $default-column-count) {
  @include breakpoint(large) {
    width: 100%;
    max-width: calc(#{$grid-columns} / 12 * 100%);
    margin-left: auto;
    margin-right: auto;
  }
}

// Main mon grid mixin
@mixin mon-grid($grid-columns: $default-grid-columns, $grid-column-gap: $default-grid-gap, $grid-row-gap: $default-grid-gap) {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 100%;

  @supports (display: grid) {
    display: grid;
    grid-column-gap: $grid-column-gap;
    grid-row-gap: $grid-row-gap;
    grid-template-columns: repeat($grid-columns, 1fr);
  }
}

// Mixin for mon row
@mixin mon-row() {
  margin: 0 auto;
  max-width: $global-width;
  padding: 0 $global-padding;
  width: 100%;
}
