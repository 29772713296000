//  Table of Contents:
//
//   1. Global
//   2. Breakpoints
//   3. Base Typography

@import 'util/util';

// 1. Global
// ---------
$global-font-size: 100%;
$global-width: rem-calc(1200);
$global-width-outer: rem-calc(1440);
$global-lineheight: 1.5;
$foundation-palette: (
	primary: #67FFBD,
	secondary: #002A3A,
	success: #3adb76,
	warning: #ffae00,
	alert: #cc4b37,
);
$light-gray: #e6e6e6;
// This is the only gray used in the designs.
$medium-gray: #A59C94;
$dark-gray: #242935;
$black: #000000;
$white: #ffffff;
$yellow: #FFE900;
$oxygen: #5BC5F2;

@include add-foundation-colors;
$gradient-light-gray: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(230,230,230,1) 100%);
$gradient-green: linear-gradient(90deg, $yellow 0%, $primary-color 100%);

$body-background: $white;
$body-font-color: $secondary-color;
$body-font-family: 'Effra', "Helvetica Neue", "Arial", sans-serif;
//$card-title-font-family: 'Pirulen', "Helvetica Neue", "Arial", sans-serif;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-normal: 400;
$global-weight-medium: 500;
$global-weight-semi-bold: 600;
$global-weight-bold: 700;
$global-weight-extra-bold: 800;
$global-weight-black: 900;
$global-radius: 0;
$global-box-shadow: 0 0 20px 0 rgba(0,0,0,.1);

// Applied when opening the off canvas or search.
$global-overlay-color: rgba(0, 0, 0, .5);

// Adds a standard padding to the main content wrapper. May need adjusting per project but allows us to avoid additional JS and CLS by dynamically calculating through JS
$sticky-header-padding-default: 90px;
$sticky-header-padding-large: 120px;

$responsive-landscape-padding: 75%;
$responsive-widescreen-padding: 48.25%;

// 2. Breakpoints
// --------------

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
  xxxlarge: 1920px,
);
$print-breakpoint: large;
$breakpoint-classes: (small medium large xlarge xxlarge xxxlarge);

// 3. Grid. Used by mon-grid and some blocks
// --------------
$default-grid-columns: 1;
$default-grid-gap: 32px;
$default-column-count: 12;

// 4. Base Typography
// ------------------
$header-font-family: $body-font-family;
$header-font-weight: $global-weight-bold;
$header-font-style: normal;
$font-family-monospace: Consolas, 'Liberation Mono', Courier, monospace;
$header-color: inherit;
$header-lineheight: 110%;
$sub-header-lineheight: 1.2;
$header-margin-bottom: 1rem;
$header-letter-spacing: normal;

// If we have hero units with larger than h1 headings, use the h0 for this. If not set the h0 to be the same as the h1.
$header-styles: (
  small: (
	'h0': ('font-size': 52, 'line-height': $header-lineheight, 'letter-spacing': $header-letter-spacing, 'margin-top': 0, 'margin-bottom': $header-margin-bottom * 2),
	'h1': ('font-size': 52, 'line-height': $header-lineheight, 'letter-spacing': $header-letter-spacing, 'margin-top': 0, 'margin-bottom': $header-margin-bottom * 2),
	'h2': ('font-size': 38, 'line-height': $header-lineheight,'letter-spacing': $header-letter-spacing,  'margin-top': 0, 'margin-bottom': $header-margin-bottom),
	'h3': ('font-size': 38, 'line-height': $header-lineheight,'letter-spacing': $header-letter-spacing,  'margin-top': 0, 'margin-bottom': $header-margin-bottom),
	'h4': ('font-size': 32, 'line-height': $header-lineheight,'letter-spacing': $header-letter-spacing,  'margin-top': 0, 'margin-bottom': $header-margin-bottom),
	'h5': ('font-size': 28, 'line-height': $header-lineheight,'letter-spacing': $header-letter-spacing,  'margin-top': 0, 'margin-bottom': $header-margin-bottom),
	'h6': ('font-size': 22, 'line-height': $header-lineheight,'letter-spacing': $header-letter-spacing,  'margin-top': 0, 'margin-bottom': $header-margin-bottom),
  ),
  medium: (
	'h0': ('font-size': 82, 'line-height': $header-lineheight, 'letter-spacing': $header-letter-spacing, 'margin-top': 0, 'margin-bottom': $header-margin-bottom * 2.5),
	'h1': ('font-size': 82, 'line-height': $header-lineheight, 'letter-spacing': $header-letter-spacing, 'margin-top': 0, 'margin-bottom': $header-margin-bottom * 2.5),
	'h2': ('font-size': 58, 'line-height': $header-lineheight, 'letter-spacing': $header-letter-spacing, 'margin-top': 0, 'margin-bottom': $header-margin-bottom),
	'h3': ('font-size': 36, 'line-height': $header-lineheight, 'letter-spacing': $header-letter-spacing, 'margin-top': 0, 'margin-bottom': $header-margin-bottom),
	'h4': ('font-size': 32, 'line-height': $header-lineheight, 'letter-spacing': $header-letter-spacing, 'margin-top': 0, 'margin-bottom': $header-margin-bottom),
	'h5': ('font-size': 28, 'line-height': $header-lineheight, 'letter-spacing': $header-letter-spacing, 'margin-top': 0, 'margin-bottom': $header-margin-bottom),
	'h6': ('font-size': 22, 'line-height': $header-lineheight, 'letter-spacing': $header-letter-spacing, 'margin-top': 0, 'margin-bottom': $header-margin-bottom),
  ),
);
$header-text-rendering: optimizeLegibility;
$small-font-size: 80%;
$header-small-font-color: $medium-gray;
$paragraph-lineheight: 160%;
$paragraph-margin-bottom: 1.2rem;
$paragraph-text-rendering: optimizeLegibility;
$code-color: $black;
$code-font-family: $font-family-monospace;
$code-font-weight: $global-weight-normal;
$code-background: $light-gray;
$code-border: 1px solid $medium-gray;
$code-padding: rem-calc(2 5 1);
$anchor-color: $primary-color;
$anchor-color-hover: scale-color($anchor-color, $lightness: -14%);
$anchor-text-decoration: none;
$anchor-text-decoration-hover: none;
// Dont forget this!
$anchor-visted-color: $secondary-color;
$hr-width: $global-width;
$hr-border: 1px solid $medium-gray;
$hr-margin: rem-calc(20) auto;
$list-lineheight: $paragraph-lineheight;
$list-margin-bottom: $paragraph-margin-bottom;
$list-style-type: disc;
$list-style-position: outside;
$list-side-margin: 1.25rem;
$list-nested-side-margin: 1.25rem;
$defnlist-margin-bottom: 1rem;
$defnlist-term-weight: $global-weight-bold;
$defnlist-term-margin-bottom: 0.3rem;
$blockquote-color: $body-font-color;
$blockquote-padding: 0;
$blockquote-border: 0;
$cite-font-size: rem-calc(20);
$cite-font-style: normal;
$cite-color: $body-font-color;
$cite-pseudo-content: '';
$keystroke-font: $font-family-monospace;
$keystroke-color: $black;
$keystroke-background: $light-gray;
$keystroke-padding: rem-calc(2 4 0);
$keystroke-radius: $global-radius;
$abbr-underline: 1px dotted $black;

// XX. Mixins. All mixins should be in one place, at the bottom so they can use the variables above.
// -----------
@import "mixins-layout";
@import "mixins-buttons";
@import "mixins-responsive-embed";
